/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    strong: "strong",
    p: "p",
    em: "em",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, React.createElement(_components.strong, null, "Polishing the Chain: Treaty Relations in Toronto")), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Polishing the Chain: Treaty Relations in Toronto"), " was the 2021-22 edition of York University’s Faculty of Environmental & Urban Change annual seminar series. Six webinars were held over the academic year, featuring Indigenous and allied scholars, knowledge holders, artists, and activists who explored the spirit and intent of Toronto treaties, the ways Indigenous peoples have and continue to uphold them, the extent to which they are (and are not) reflected in contemporary Indigenous / state relations, and the possibilities these open for working towards conciliation and establishing right relations with each other, and the Land."), "\n", React.createElement("img", {
    className: "toom turtle",
    src: "/tooms/tooms-turtle.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Seminar 1 -"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=HFdxbqLiAAk"
  }, React.createElement(_components.strong, null, "The Symbolic Language of Wampum Diplomacy"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    target: "_blank",
    href: "/pdfs/Polishing the Chain Seminar 1.pdf"
  }, "Edited Transcript (PDF)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Speakers: Rick Hill"), " – Writer, artist, historian, and curator from Six Nations of the Grand River Territory; ", React.createElement(_components.strong, null, "Dr. Alan Ojig Corbiere"), " – Associate Professor, History, York University, Canada Research Chair in Indigenous History of North America; ", React.createElement(_components.strong, null, "Ange Loft"), " – Associate Director, Jumblies Theatre and Arts"), "\n", React.createElement(_components.p, null, "At the 1764 Treaty of Niagara, the British extended their nearly century-old Covenant Chain alliance with the Haudenosaunee, to the 24 Western Nations of the Great Lakes area. In this talk, Dr. Alan Ojig Corbiere and Rick Hill discussed the Covenant Chain, the visual and metaphoric language of wampum diplomacy, and explored the symbolism represented in the 1764 and 24 Nations belts delivered at Niagara to secure this crucial alliance. The symbolism inscribed in these belts drew from the Dish with One Spoon Wampum, and would have been used deliberately as a means of securing relations with Indigenous nations. Ange Loft discussed the ways this visual language is deployed in her current A Treaty Guide for Torontonians and Dish Dances, both of which will be featured in the 2022 Toronto Biennial of Art."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "The Symbolic Language of Wampum Diplomacy"), " was co-presented with the Toronto Biennial of Art."), "\n", React.createElement("img", {
    className: "toom dish-belt",
    src: "/tooms/tooms-dish-belt.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Seminar 2 -"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=MPi4Kf2sfA0"
  }, React.createElement(_components.strong, null, "Taking Care of the Dish: Treaties, Indigenous Law and Environmental Justice Seminar"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    target: "_blank",
    href: "/pdfs/Polishing the Chain Seminar 2.pdf"
  }, "Edited Transcript (PDF)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Speakers: Dr. Deborah McGregor"), " – Associate Professor and Canada Research Chair in Indigenous Environmental Justice, Osgoode Hall Law School and the Faculty of Environmental & Urban Change, York University; ", React.createElement(_components.strong, null, "Carolynne Crawley"), " – Founder of Msit No’kmaq; ", React.createElement(_components.strong, null, "Dr. Adrianne Lickers Xavier"), " – Assistant Professor, Indigenous Studies, McMaster University"), "\n", React.createElement(_components.p, null, "Indigenous/Crown treaties are not moments where colonial law was imposed. They represent a meeting between Indigenous and colonial legal orders. To understand our treaty relations, we must understand the Indigenous laws, knowledge systems and visions of justice they are grounded in. In this talk speakers reflected on their work in Indigenous Environmental Justice in relation to Indigenous law and treaties, to explore the ways these agreements guide Indigenous Land stewardship, and ways they are being lived in Toronto and Southern Ontario today."), "\n", React.createElement("img", {
    className: "toom bird",
    src: "/tooms/tooms-bird.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Seminar 3 -"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=SNOrrcGLE5k"
  }, React.createElement(_components.strong, null, "Treaty Relations, Planning, and Indigenous Consultation in the City of Toronto"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    target: "_blank",
    href: "/pdfs/Polishing the Chain Seminar 3.pdf"
  }, "Edited Transcript (PDF)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Speakers: Selina Young"), " – Director, Indigenous Affairs Office for the City of Toronto; ", React.createElement(_components.strong, null, "Dr. Leela Viswanathan"), " – Adjunct Associate Professor in the Department of Geography and Planning at Queen's University. Founder and Principal, Viswali Consulting; ", React.createElement(_components.strong, null, "Bob Goulais –"), " Founder, President and Senior Principal, Nbisiing Consulting."), "\n", React.createElement(_components.p, null, "Treaties, the Crown’s Duty to Consult, and Ontario’s Provincial Planning Policy Statement have triggered new practices of Indigenous consultation and urban planning in Toronto. To what extent does city planning include Indigenous nations and communities? To what extent do Indigenous peoples have meaningful authority or decision-making power in relation to Land and Waters? To what extent does the City recognize and enable their ability to practice ceremony, plant and harvest food and medicines, or enact stewardship responsibilities?"), "\n", React.createElement("img", {
    className: "toom cradle-board",
    src: "/tooms/tooms-cradle-board.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Seminar 4 -"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=mRfeehn8lVc"
  }, React.createElement(_components.strong, null, "The Forgotten Promise of Niagara"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    target: "_blank",
    href: "/pdfs/Polishing the Chain Seminar 4.pdf"
  }, "Edited Transcript (PDF)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Speakers- Dr. Hayden King"), ", Executive Director, Yellowhead Institute; ", React.createElement(_components.strong, null, "Dr. Eva Jewel"), ", Research Director, Yellowhead Institute; ", React.createElement(_components.strong, null, "Vanessa Dion-Fletcher,"), " Artist."), "\n", React.createElement(_components.p, null, "The 1764 Treaty of Niagara is the foundational agreement between the Crown and the Anishinaabek, and a moment of renewal of the foundational Covenant Chain or Two Row Wampum between the Haudenosaunee and Crown. Here the 1763 Royal Proclamation, which announced British arrival and supposed sovereignty in the region, was transformed by Indigenous partners as it was adopted as treaty. Many see Niagara as a constitutional moment anchored in Indigenous and British legal traditions. British promises at Niagara included recognition of Indigenous title and sovereignty, and an on-going commitment to peaceful coexistence and trade for mutual benefit. Indigenous peoples would never sink into poverty. Importantly, The Treaty of Niagara is a foundational context for all subsequent agreements Indigenous nations made with the Crown. In this talk, speakers explored the significance of this agreement and how (or if) implementing Niagara could contribute towards decolonization and Indigenous calls for Land Back."), "\n", React.createElement("img", {
    className: "toom mwheel",
    src: "/tooms/tooms-mwheel.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Seminar 5 -"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=2OdRR-UvbBc"
  }, React.createElement(_components.strong, null, "The So-Called Toronto Purchase"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    target: "_blank",
    href: "/pdfs/Polishing the Chain Seminar 5.pdf"
  }, "Edited Transcript (PDF)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Speakers: Margaret Sault"), " – acting Executive Director of Intergovernmental Affairs for the Mississaugas of the Credit First Nation; ", React.createElement(_components.strong, null, "Bryan Laforme"), " – former Chief of the Mississaugas of the Credit First Nation; ", React.createElement(_components.strong, null, "Carolyn King"), " – former Chief of the Mississaugas of the Credit First Nation; Creator of the Moccasin Identifier Project"), "\n", React.createElement(_components.p, null, "Speakers from the Mississaugas of the Credit discuss the history and legacy of the 1787/1805 Toronto “Purchase” and shared Mississauga oral history and knowledge of the agreement. What was the spirit and intent of this agreement, from a Mississauga perspective? What kind of authority or recognition has come out of the 2010 Specific Claim related to the “Purchase”? What efforts are underway for the Mississaugas to maintain, and strengthen relations with the Lands and waters of the GTA? How should we, as Torontonians, honor this agreement?"), "\n", React.createElement("img", {
    className: "toom dodem",
    src: "/tooms/tooms-dodem.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Seminar 6 -"), " ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=93gWboMYu90"
  }, React.createElement(_components.strong, null, "We are all Treaty People"))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    target: "_blank",
    href: "/pdfs/Polishing the Chain Seminar 6.pdf"
  }, "Edited Transcript (PDF)"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Speakers: Leah Decker"), " – Canada Research Chair in Creative Technologies, NSCAD University; ", React.createElement(_components.strong, null, "Adrian Smith"), " – Professor, Osgoode Hall Law School; ", React.createElement(_components.strong, null, "Chris Ramsaroop"), " – Organizer, Justicia for Migrant Workers; ", React.createElement(_components.strong, null, "Sarah Rotz"), " – Assistant Professor, York University; ", React.createElement(_components.strong, null, "Lauren Kepkiewitz"), " – Banting Post-Doctoral Fellow, University of Manitoba"), "\n", React.createElement(_components.p, null, "In this panel, speakers explored how as artists and/or scholars involved in non-Indigenous led social movements, they understand and take up their treaty responsibilities."), "\n", React.createElement("img", {
    className: "toom council",
    src: "/tooms/tooms-council.png"
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Polishing the Chain: Treaty Relations in Toronto"), " was presented by York University’s Faculty of Environmental & Urban Change in partnership with York’s Centre for Indigenous Knowledges and Languages, Dr. Deborah McGregor’s Indigenous Environmental Justice Project and Jumblies Theatre + Art’s Talking Treaties, with support from the Toronto Biennial of Art, York’s Vice-President Research & Innovation, York University’s Indigenous Teaching and Learning Fund, Osgoode Hall Law School, York University Faculty Association - Community Projects, and Lisa Myers’ York Research Chair in Indigenous Curatorial Practice."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
